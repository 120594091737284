import { graphql } from "gatsby";
import React from "react";
import ArticleTemplate from "../../templates/Article/Article";

const Article = ({ location, data: { allArticles, article } }) => {
  const { href: url } = location;
  return (
    <ArticleTemplate article={article} allArticles={allArticles} url={url} />
  );
};

export default Article;

export const query = graphql`
  query GetArticle($slug: String) {
    article: strapiInsights(slug: { eq: $slug }) {
      metaData {
        pageTitle
        metadescription
      }
      multiComponent
      id
      published_at
      publish_at
      authors {
        id
        name
        published_at
      }
      client_types {
        Type
      }
      copy
      featureVideo {
        id
        title
        video {
          localFile {
            publicURL
          }
        }
        transcript {
          localFile {
            id
            publicURL
          }
        }
        poster {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1440)
            }
          }
        }
      }
      image {
        longDesc
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1440)
            }
            publicURL
          }
          url
        }
      }
      imagePopUpForm {
        confirmationCopy
        mainCopy
        heading
        hubspotCode
      }
      media_type {
        tyoe
      }
      shortDesc
      subtitle
      slug
      continueReading {
        articlesHeading
        insights {
          id
          copy
          publish_at
          shortDesc
          slug
        }
        case_studies {
          id
        }
        events {
          id
        }
        press_releases {
          id
        }
      }
    }

    allArticles: allStrapiInsights(
      sort: { fields: publish_at, order: DESC }
      filter: {
        media_type: { tyoe: { eq: "Article" } }
        publish_at: { ne: null }
      }
    ) {
      nodes {
        authors {
          id
          name
          published_at
        }
        id
        shortDesc
        strapiId
        slug
        subtitle
        publish_at
        media_type {
          tyoe
        }
        image {
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(height: 80, width: 122)
              }
            }
          }
        }
      }
    }
  }
`;
